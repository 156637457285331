import * as React from 'react';

export interface ThemePreference {
  theme: string | null;
  lightTheme: string | null;
}

export interface ThemePreferenceContextState extends ThemePreference {
  setTheme: (theme: string) => void;
  setLightTheme: (theme: string) => void;
}

export const ThemePreferenceContext = React.createContext<ThemePreferenceContextState>({
  theme: null,
  setTheme: () => null,
  lightTheme: null,
  setLightTheme: () => null,
});
