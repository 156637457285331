import { OnSubscriptionDataOptions } from '@apollo/client';
import { useStorage } from '@qfa/react-components';
import {
  useKineticUserPreferenceQuery,
  UserPreferenceUpdatedSubscription,
  useSaveKineticUserPrefMutationMutation,
  useSaveUserPrefMutation,
  useUserPreferenceQuery,
  useUserPreferenceUpdatedSubscription,
} from 'generated/graphql';
import { isEqual, throttle } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { PREFERENCE_KEYS, usePreferenceData } from 'types/preferenceTypes';

export const usePreference = <T>(
  key: PREFERENCE_KEYS,
  cacheStorage = false,
  value?: T,
  onSubscriptionData?: (
    options: OnSubscriptionDataOptions<UserPreferenceUpdatedSubscription>
  ) => void,
  throttleSaveMs = 0
): usePreferenceData<T> => {
  const [storagePref, setStoragePref] = useStorage<Partial<T>>({
    key,
    value: value ?? {},
  });

  const result = useUserPreferenceQuery({
    fetchPolicy: 'cache-first',
    variables: {
      key,
    },
  });

  const { data, error, loading, refetch } = result;

  const latestValue = useRef(null);

  const throttledRefetch = useRef(throttle(refetch, 500));

  useUserPreferenceUpdatedSubscription({
    variables: { key },
    onSubscriptionData: (data) => {
      const updatedValue = data.subscriptionData.data.userPreferenceUpdated.userPreference.value;
      if (isEqual(updatedValue, latestValue.current)) {
        return;
      }
      if (!onSubscriptionData) {
        throttledRefetch.current();
        return;
      }
      onSubscriptionData(data);
    },
  });

  let preference = data?.kineticUser?.preference as T;
  if (cacheStorage && !preference && storagePref) {
    preference = storagePref as unknown as T;
  }

  const [savePreferenceMutation, savePreferenceResult] = useSaveUserPrefMutation();

  const savePreference = useCallback(
    (value: T) => {
      if (cacheStorage) {
        setStoragePref(value);
      }
      latestValue.current = value;
      return savePreferenceMutation({
        // Since the return types on preferences are JSON they aren't cached and need to be refetched.
        onCompleted: () => result.refetch(),
        variables: {
          key,
          value,
        },
      });
    },
    [cacheStorage, key, result, savePreferenceMutation, setStoragePref]
  );

  const throttledSave = useMemo(() => {
    return throttle(savePreference, throttleSaveMs);
  }, [savePreference, throttleSaveMs]);

  const clearPreference = useCallback(() => {
    if (cacheStorage) {
      setStoragePref({});
    }

    return savePreferenceMutation({
      variables: {
        key,
        value: {},
      },
    });
  }, [cacheStorage, key, savePreferenceMutation, setStoragePref]);

  return [
    { key, loading, preference, error },
    { savePreference: throttledSave, clearPreference, savePreferenceResult },
  ];
};

/**
 * Use a kinetic wide user preference. Does not take into account the current org.
 */
export const useKineticPreference = <T>(
  key: PREFERENCE_KEYS,
  cacheStorage = false,
  value?: T,
  onSubscriptionData?: (
    options: OnSubscriptionDataOptions<UserPreferenceUpdatedSubscription>
  ) => void,
  throttleSaveMs = 0
): usePreferenceData<T> => {
  const [storagePref, setStoragePref] = useStorage<Partial<T>>({
    key,
    value: value ?? {},
  });

  const result = useKineticUserPreferenceQuery({
    fetchPolicy: 'cache-first',
    variables: {
      key,
    },
  });

  const { data, error, loading, refetch } = result;

  const throttledRefetch = useRef(throttle(refetch, 500));

  const latestValue = useRef(null);

  useUserPreferenceUpdatedSubscription({
    variables: { key },
    onSubscriptionData: (data) => {
      const updatedValue = data.subscriptionData.data.userPreferenceUpdated.userPreference.value;

      if (isEqual(updatedValue, latestValue.current)) {
        return;
      }

      if (!onSubscriptionData) {
        throttledRefetch.current();
        return;
      }
      onSubscriptionData(data);
    },
  });

  let preference = data?.kineticUser?.kineticPreference as T;
  if (cacheStorage && !preference && storagePref) {
    preference = storagePref as unknown as T;
  }

  const [savePreferenceMutation, savePreferenceResult] = useSaveKineticUserPrefMutationMutation({
    // Since the return types on preferences are JSON they aren't cached and need to be refetched.
    onCompleted: () => result.refetch(),
  });

  const savePreference = useCallback(
    (value: T) => {
      if (cacheStorage) {
        setStoragePref(value);
      }
      latestValue.current = value;
      return savePreferenceMutation({
        variables: {
          key,
          value,
        },
      });
    },
    [cacheStorage, key, savePreferenceMutation, setStoragePref]
  );

  const throttledSave = useMemo(() => {
    return throttle(savePreference, throttleSaveMs);
  }, [savePreference, throttleSaveMs]);

  const clearPreference = useCallback(() => {
    if (cacheStorage) {
      setStoragePref({});
    }
    return savePreferenceMutation({
      variables: {
        key,
        value: {},
      },
    });
  }, [cacheStorage, key, savePreferenceMutation, setStoragePref]);

  return [
    { key, loading, preference, error },
    { savePreference: throttledSave, clearPreference, savePreferenceResult },
  ];
};
