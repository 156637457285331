export const MODULE_FLAG_PREFIX = 'module_';

let devModules = [];
const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
  try {
    const names = process.env.REACT_APP_KINETIC_MODULES?.split(',');
    devModules = names.map((name) => ({ name, isActive: true }));
  } catch (e) {
    console.warn('Failed to load kinetic modules');
  }
}

// TODO: Load module flags from API
export const useModuleFlagContext = () => {
  return devModules;
};
