import account from './en/account.json';
import app from './en/app.json';
import common from './en/common.json';
import contacts from './en/contacts.json';
import detectors from './en/detectors.json';
import events from './en/events.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import integrations from './en/integrations.json';
import lastterm from './en/lastterm.json';
import location from './en/location.json';
import maps from './en/maps.json';
import reports from './en/reports.json';
import scheduler from './en/scheduler.json';
import sessionDialog from './en/sessionDialog.json';
import signals from './en/signals.json';
import signs from './en/signs.json';
import timezone from './en/timezone.json';
import traveltime from './en/traveltime.json';
import tunnels from './en/tunnels.json';
import video from './en/video.json';
import weather from './en/weather.json';
import workspace from './en/workspace.json';
import corridors from './en/corridors.json';
import emails from './en/emails.json';
import communicationlogs from './en/communicationlogs.json';
import fleet from './en/fleet.json';

// Please keep these alphabetical
export const resources = {
  en: {
    account,
    app,
    common,
    communicationlogs,
    contacts,
    detectors,
    emails,
    events,
    fleet,
    integrations,
    lastterm,
    location,
    maps,
    reports,
    sessionDialog,
    signals,
    signs,
    traveltime,
    timezone,
    video,
    workspace,
    scheduler,
    weather,
    tunnels,
    corridors,
  },
} as const;

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    lng: 'en',
    resources,
  });
};

export default i18n;
