import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePreference } from 'kineticUtils/hooks';
import { PREFERENCE_KEYS } from 'types/preferenceTypes';
import { ThemePreference } from '..';

export const useThemePreferenceContext = () => {
  const [{ preference }] = usePreference<ThemePreference>(PREFERENCE_KEYS.THEME, true);
  const [themePreference, setThemePreference] = useState<string | null>(preference.theme ?? null);
  const [lightThemePreference, setLightTheme] = useState<string | null>('default');
  const setTheme = useCallback((theme: string) => {
    setThemePreference(theme);
  }, []);

  useEffect(() => {
    if (preference.theme && preference.theme !== themePreference) {
      setThemePreference(preference.theme);
    }
  }, [preference, themePreference]);

  const contextValue = useMemo(() => {
    return {
      theme: themePreference,
      lightTheme: lightThemePreference,
      setTheme,
      setLightTheme,
    };
  }, [themePreference, setTheme, lightThemePreference, setLightTheme]);
  return contextValue;
};
