import { NullableUser, UnitType, UserContextData } from 'contexts/UserContext/UserContext';
import {
  KineticLicenseFeature,
  KineticModule,
  useEffectivePrivilegesQuery,
  useEffectivePrivilegesUpdatedSubscription,
  useKineticUserQuery,
  useLicenseFeaturesQuery,
  useUserSignalPrivilegesQuery,
} from 'generated/graphql';
import { groupBy, mapValues } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

const nullUser = {
  id: null,
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  picture: null,
};

export const useUserContext = (): UserContextData => {
  const { data, loading, error } = useKineticUserQuery();
  const [user, setUser] = useState<NullableUser>(nullUser);

  const unit: UnitType = useMemo(
    () => (navigator.language === 'en-US' ? 'imperial' : 'metric'),
    []
  );

  const results = useEffectivePrivilegesQuery();
  useEffectivePrivilegesUpdatedSubscription({
    onSubscriptionData: () => results.refetch(),
  });

  const signalsPrivilegesResult = useUserSignalPrivilegesQuery();
  const signalsPrivileges = signalsPrivilegesResult?.data?.userSignalPrivileges;

  const licensesResult = useLicenseFeaturesQuery();

  const enabledLicenses = licensesResult?.data?.licenseFeatures
    ?.filter((license) => license.enabled)
    .map((module) => module.feature);

  const enabledModules = useMemo(() => {
    const licenses = enabledLicenses || [];
    return [
      KineticModule.Integrations,
      KineticModule.System,
      KineticModule.Contacts,
      KineticModule.Emails,
      ...licenses,
    ];
  }, [enabledLicenses]);

  const privileges = useMemo(() => {
    if (!results.data || !enabledLicenses) {
      return;
    }
    const mappedPrivileges = mapValues(
      groupBy(results.data.effectivePrivileges, 'kineticModule'),
      (list) => groupBy(list, 'privilegeType')
    );

    // Disable privileges if there is no license.
    Object.values(KineticLicenseFeature).forEach((feature) => {
      if (!enabledLicenses.includes(feature)) {
        mappedPrivileges[feature] = {};
      }
    });

    return mappedPrivileges;
  }, [results, enabledLicenses]);

  useEffect(() => {
    if (data?.kineticUser) {
      setUser(data.kineticUser);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setUser(nullUser);
    }
  }, [error]);

  const contextValue = useMemo(
    () => ({
      ...user,
      privileges,
      signalsPrivileges,
      unit,
      ready: !loading && !error && !!user && !!privileges,
      modules: enabledModules as KineticModule[],
    }),
    [user, privileges, signalsPrivileges, loading, error, unit, enabledModules]
  );

  return contextValue;
};
